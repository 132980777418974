import store from '@/store';
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';

export default function useParksList() {
    const parkListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Email'), value: 'email', class: 'text-uppercase' },
        { text: i18n.t('District'), value: 'district.name', class: 'text-uppercase' },
        { text: i18n.t('County'), value: 'county.name', class: 'text-uppercase' },
        { text: i18n.t('Parish'), value: 'parish.name', class: 'text-uppercase' },
        { text: i18n.t('Managers'), value: 'managers', class: 'text-uppercase' },
        { text: i18n.t('Lotation'), value: 'lote', width: '10rem', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const countryFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalParkListTable = parkListTable.length;
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const parkTotalLocal = ref([]);
    const selectedRows = ref([]);
    const oldSearch = ref('');
    const oldOcupationFilter = ref([0, 100]);
    const oldManagerFilter = ref(' ');
    const oldDistrictFilter = ref(' ');
    const oldCountryFilter = ref(' ');
    const oldCountyFilter = ref(' ');
    const oldParishFilter = ref(' ');

    // fetch data
    const fetchParkList = () => {
        let verifyQuery = store.getters['app-park/getParkQuery'];
        let isDeletePark = store.getters['app-park/getIsDeletePark'];
        store
            .dispatch('app-park/fetchParkList', {})
            .then((response) => {
                if (isDeletePark) {
                    parkListTable.value = [];
                    parkListTable.value.push(...response.data.data);
                    store.commit('app-park/setIsDeletePark', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].name.$containsi &&
                    oldOcupationFilter.value[0] == verifyQuery.filters.occupationPercentage.$between[0] &&
                    oldOcupationFilter.value[1] == verifyQuery.filters.occupationPercentage.$between[1] &&
                    oldManagerFilter.value == verifyQuery.filters.managers.id.$containsi &&
                    oldDistrictFilter.value == verifyQuery.filters.district.id.$eq &&
                    oldCountryFilter.value == verifyQuery.filters.country.id.$containsi &&
                    oldCountyFilter.value == verifyQuery.filters.county.id.$containsi &&
                    oldParishFilter.value == verifyQuery.filters.parish.id.$containsi
                ) {
                    parkListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].name.$containsi;
                    oldOcupationFilter.value = verifyQuery.filters.occupationPercentage.$between;
                    oldManagerFilter.value = verifyQuery.filters.managers.id.$containsi;
                    oldDistrictFilter.value = verifyQuery.filters.district.id.$eq;
                    oldCountryFilter.value = verifyQuery.filters.country.id.$containsi;
                    oldCountyFilter.value = verifyQuery.filters.county.id.$containsi;
                    oldParishFilter.value = verifyQuery.filters.parish.id.$containsi;
                    parkListTable.value = [];
                    parkListTable.value.push(...response.data.data);
                }

                totalParkListTable.value = response.data.meta.pagination.total;
                // parkTotalLocal.value = parkTotal;1

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, countryFilter, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchParkList();
    });

    const resolveParkPicture = (pictures) => {
        if (_.get(pictures, '[0].formats.thumbnail.url')) return _.get(pictures, '[0].formats.thumbnail.url');

        if (_.get(pictures, 'url')) return _.get(pictures, 'url');
        return null;
    };
    const resolveParkPictureMax = (pictures) => {
        if (_.get(pictures, '[0].formats.large.url')) return _.get(pictures, '[0].formats.large.url');

        if (_.get(pictures, '[0].url')) return _.get(pictures, '[0].url');
        return null;
    };
    const resolveParkRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveParkRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveParkStatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const resolveParkTotalIcon = (total) => {
        if (total === 'Total Parks') return { icon: mdiAccountOutline, color: 'primary' };
        if (total === 'Paid Parks') return { icon: mdiAccountPlusOutline, color: 'error' };
        if (total === 'Active Parks') return { icon: mdiAccountCheckOutline, color: 'success' };
        if (total === 'Pending Parks') return { icon: mdiAccountRemoveOutline, color: 'warning' };

        return { icon: mdiAccountOutline, color: 'primary' };
    };

    const limitParkText = (parks) => {
        if (parks.length > 1) {
            return ' + ' + (parks.length - 1);
        }
    };

    return {
        parkListTable,
        tableColumns,
        searchQuery,
        countryFilter,
        roleFilter,
        planFilter,
        statusFilter,
        totalParkListTable,
        loading,
        options,
        parkTotalLocal,
        selectedRows,
        fetchParkList,
        resolveParkPicture,
        resolveParkPictureMax,
        resolveParkRoleVariant,
        resolveParkRoleIcon,
        resolveParkStatusVariant,
        limitParkText,
    };
}
